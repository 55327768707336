import { ref, shallowRef } from 'vue'

import en from './en.js'
import zh from './zh.js'

const transTables = {
  EN: en, 
  ZH: zh
}

const lang = ref('EN')
const transTable = shallowRef(en)

export default {
  install: (app, options) => {
    app.config.globalProperties.$t = (key) => {
      return transTable.value[key]
    }
    app.config.globalProperties.$lang = () => {
      return lang.value
    }
  },
  setLang: (langKey) => {
    lang.value = langKey
    transTable.value = transTables[langKey]
  }
}

