import 'animate.css'
import 'swiper/css/swiper.css'
import WOW from 'wow.js'
import { createApp } from 'vue'
import i18n from "@/plugins/i18n"
import router from './router'
import store from './store'
import App from './App.vue'

new WOW().init()

createApp(App).use(router).use(store).use(i18n).mount('#app')
