<template>
  <div class="vpt-button" :class="{
      'large-size': size == 'large',
      'normal-size': size == 'normal',
      'small-size': size == 'small',
      'no-border': noBorder,
      primary: primary, 
      secondary: secondary, 
      compact: compact,
      white: white,
      'vpt-disabled': disabled,
      'align-left': alignLeft,
      'align-right': alignRight,
    }" :style="{
      'flex-grow': flexGrow,
      height: height,
    }"
    @click.stop="onClick">
    <slot></slot>
  </div>
</template>

<script setup>
import { normalizeClass, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router'

const props = defineProps({
  disabled: Boolean,
  flexGrow: {
    type: String,
    default: ''
  },
  size: {
    type: String,
    default: 'medium'
  },
  height: String,
  noBorder: Boolean, 
  primary: Boolean,
  secondary: Boolean,
  compact: Boolean,
  white: Boolean,
  alignLeft: Boolean,
  alignRight: Boolean,
  route: {
    type: Object,
    default: null
  }
})

const emit = defineEmits(['click'])

const router = useRouter()

function onClick() {
  if (!props.disabled) {
    if (props.route) {
      router.push(props.route)
    } else {
      emit('click')
    }
  }
}

</script>

<style scoped lang="scss">
.vpt-button {
  display: flex;
  justify-content: center;
  border: 1px solid #000000;
  border-radius: 1.5rem;
  padding: 0 1.5rem;
  line-height: 2.5rem;
  font-size: 1.25rem;
  text-align: center;
  cursor: pointer;
  transition: all 1s;
  &.compact {
    padding: 0 0.6rem;
  }
  &:hover {
    background-color: #e3dddd;
  }
  &.small-size {
    border-radius: 1.25rem;
    padding: 0 1.5rem;
    line-height: 2rem;
    font-size: 1rem;
    &.compact {
      padding: 0 0.5rem;
    }
  }
  &.large-size {
    border-radius: 1.75rem;
    padding: 0 2rem;
    line-height: 3.75rem;
    font-size: 1.5rem;
    &.compact {
      padding: 0 1rem;
    }
  }
  &.no-border {
    border: 0;
  }
  &.white {
    color: #ffffff;
    border-color: #ffffff;
  }
  &.align-left {
    justify-content: start;
  }
  &.align-right {
    justify-content: end;
  }
  &.vpt-disabled {
    cursor: not-allowed;
    border-color: #00000034;
    background-color: inherit;
    color: #00000045;
  }
  &.primary {
    background-color: #000000;
    color: #ffffff;
    &.vpt-disabled {
      background-color: #00000026;
    };
    &:hover {
      background-color: #00000090;
    }    
  }
  &.secondary {
    background-color: rgb(255, 255, 255, 0.1);
    color: #ffffff;
    &.vpt-disabled {
      background-color: #00000026;
    };
    &:hover {
      background-color: #00000090;
    }
  }
}

</style>