export default {
  about: 'About',
  all: 'All',
  accountSettings: 'Account Settings',
  cookiesSettings: 'Cookies Settings',
  contactUs: 'Contact',
  home: 'Home',
  joinWithEmail: 'Join with E-mail',
  joinWithPhone: 'Join with Phone',
  logout: 'Log Out',
  news: 'News',
  orders: 'Orders',
  password: 'Password',
  pendingDelivery: 'Pending Delivery',
  pendingPayment: 'Pending Payment',
  pendingShipping: 'Pending Shipping',
  phoneOrEmail: 'Phone or E-mail',
  privacyPolicy: 'Privacy',
  product: 'Product',
  products: 'Products',
  serviceTerms: 'Terms of Service',
  shippingAddresses: 'Shipping Addresses',
  signIn: 'Sign In',
  signUp: 'Sign Up',
  support: 'Support',
  viewptCoAddr: 'Room A1, 4th Floor, TOWER13A Building, Point City, No. 446 Jihua Road, Longgang District, Shenzhen, Guangdong Province, China',
  viewptCoFullName: 'Shenzhen Viewpoint Innovation Technology Co.',
  viewptCopyRight: 'Copyright © 2024 VIEWPT Co. All rights reserved.',
  pay: 'Pay',
  cancel: 'Cancel',
  usd: 'USD',
  cny: 'CNY',
  unitPrice: 'Unit Price',
  productAmount: 'Product Amount',
  freight: 'Freight',
  coupon: 'Coupon',
  total: 'Total',
  contactPerson: 'Contact Person',
  phoneNumber: 'Phone Number',
  addrDetails: 'Address Details',
  modifyShippingAddress: 'Modify Shipping Address',
  submit: 'Submit',
  save: 'Save',
  modify: 'Modify',
  remove: 'Remove',
  add: 'Add',
  shipingAddresses: 'Shipping Addresses',
  addShippingAddress: 'Add Shipping Address',
  selectShippingAddress: 'Select Shipping Address',
  realiaSlogan: 'Everyone can easily live stream and record 3DVR and 3D movies (videos) with just one click',
  buy: 'Buy',
  productParams: 'Product Parameters',
  loginAlipayToPay: 'Login Alipay to Pay',
  loginAlipayToPayPromptTitle: 'Please Login Alipay Site to Complete the Payment',
  orderId: 'Order ID',
  paymentAmount: 'Payment Amount',
  shippingMethod: 'Shipping Method',
  trackNumber: 'Track Number',
  contact: 'Contact',
  shippingAddress: 'Shipping Address',
  placeOrderTime: 'Order Time',
  shippingTime: 'Shipping Time',
  deliveryTime: 'Delivery Time',
  payTime: 'Payment Time',
  payMethod: 'Payment Method',
  faq: 'Common Problem',
  viewptSupport: 'VIEWPT Support',
  download: 'Download',
  firmware: 'Firmware',
  userManual: 'User Manual',
  ourTeam: 'Our Team',
  email: 'Email',
  join: 'Join',
  verificationCode: 'Verification Code',
  unitSecond: 's',
  toSend: 'Send',
  toResend: 'Resend',

  sending: 'Sending',
  inputCaptcha: 'Please input captcha',
  orderState1: 'Pending Payment',
  orderState15: 'Confirming Payment',
  orderState2: 'Pending Shipment',
  orderState3: 'Pending Delivery',
  orderState4: 'Delivered',
  orderState10: 'Completed',
  orderState11: 'Cancelled',
  confirmReceipt: 'Confirm Receipt',
  whyChooseViewpt: 'Why Choose VIEWPT',
  viewpt: 'VIEWPT Innovation',
  modifyEmail: 'Modify Email',
  newEmail: 'New Email',
  modifyPassword: 'Modify Password',
  oldPassword: 'Old Password',
  newPassword: 'New Password',
  modifyPhoneNumber: 'Modify Phone Number',
  newPhoneNumber: 'New Phone Number',
  inputValidPhoneNumberOrEmail: 'Please input valid phone number or email',
  inputPasswd: 'Please input password',
  inputValidEmail: 'Please input valid email',

}
