import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: true,
});

function getErrMsg(code, msg) {
  switch (code) {
    case 1: return 'system is busy'
    case 2: return 'network failure'
    case 3: return 'no access permission'

    case 100: return 'member already registered'
    case 101: return 'phone number taken up'
    case 102: return 'email taken up'
    case 103: return 'member not existed'
    case 104: return 'captcha sending failure'

    case 110: return 'not authenticated'
    case 111: return 'member or password not match'
    case 112: return 'captcha not match'

    case 120: return 'shipping address not exist'

    default: return 'error ' + code
  }
}

function processFail(code, errObj, config) {
  let failMsg = getErrMsg(code, errObj)
  config.failMsg && (config.failMsg.value = failMsg)
  config.fail && config.fail(failMsg)
}

function processUrl(url, config) {
  return config?.path ? url + '/' + config.path : url
}

function post(url, config) {
  instance.post(processUrl(url, config), config?.data).then((res) => {
    const code = res.data.code;
    if (code == 0) {
      config?.success && config.success(res.data.data)
    } else {
      processFail(code, res.data.msg, config)
    }
  }).catch((errObj) => {
    console.info(errObj)
    processFail(2, errObj, config)
  });
}

function get(url, config) {
  instance.get(processUrl(url, config), config?.axios).then((res) => {
    const code = res.data.code;
    if (code == 0) {
      config.success && config.success(res.data.data)
    } else {
      processFail(code, res.data.msg, config)
    }
  }).catch((errObj) => {
    processFail(2, errObj, config)
  });
}

function _delete(url, config) {
  instance.delete(processUrl(url, config), config?.axios).then((res) => {
    const code = res.data.code;
    if (code == 0) {
      config.success && config.success(res.data.data)
    } else {
      processFail(code, res.data.msg, config)
    }
  }).catch((errObj) => {
    processFail(2, errObj, config)
  });
}

export function sendCaptcha(config) {
  post('/api/member/captchas', config)
}

export function join(config) {
  post('/api/member/members', config)
}

export function login(config) {
  post('/api/member/login', config)
}

export function logout(config) {
  post('/api/member/logout', config)
}

export function changePasswd(config) {
  post('/api/member/members/me/passwd', config)
}

export function changeEmail(config) {
  post('/api/member/members/me/email', config)
}

export function changePhoneNumber(config) {
  post('/api/member/members/me/phoneNumber', config)
}

export function getShippingAddresses(config) {
  get('/api/member/members/me/shippingAddresses', config)
}

export function addShippingAddress(config) {
  post('/api/member/members/me/shippingAddresses', config)
}

export function updateShippingAddress(config) {
  post('/api/member/members/me/shippingAddress', config)
}

export function deleteShippingAddress(config) {
  _delete('/api/member/members/me/shippingAddresses', config)
}

export function getProducts(config) {
  return get('/api/member/products', config)
}

export function placeOrder(config) {
  return post('/api/member/members/me/orders', config)
}

export function getOrders(config) {
  return get('/api/member/members/me/orders', config)
}

export function updateOrder(config) {
  return post('/api/member/members/me/orders', config)
}

export function payOrder(config) {
  return post('/api/member/members/me/orders', config)
}

export function cancelOrder(config) {
  return _delete('/api/member/members/me/orders', config)
}

export function confirmReceipt(config) {
  return post('/api/member/members/me/orders', config)
}
