<template>
  <div class="swiper-container" ref="swiperContainer" :style="{
    height: height,  
  }">
    <div class="swiper-wrapper">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>

import Swiper from 'swiper'

import { ref, onMounted } from 'vue'

const props = defineProps({
  height: String,
})

const swiperContainer = ref(null)

onMounted(() => {
  new Swiper(swiperContainer.value, {
    loop: true,
        // autoplay: {
        //     delay: 3000,
        //     disableOnInteraction: false
        // },
        effect: 'coverflow',
        slidesPerView: 1,
        centeredSlides: false,
        coverflowEffect: {
            rotate: 10,
            stretch: 10,
            depth: 60,
            modifier: 2,
            slideShadows: false,
        },

        breakpoints: {
            //当宽度小于等于768时显示
            720: {

              slidesPerView: 3,
                centeredSlides: false,
                coverflowEffect: {
                    rotate: 30,
                    stretch: 10,
                    depth: 60,
                    modifier: 2,
                    slideShadows: false,
                },
            },
        },
  })
})

</script>

<style scoped lang="scss">

</style>