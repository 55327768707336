<template>
  <vpt-v-group size="large" :black-bg="blackBg" >
    <vpt-h-group class="pw-24">
      <vpt-text size="h3" :white="blackBg" flex-grow="1">{{ title }}</vpt-text>
      <vpt-button size="large" :white="blackBg" ref="prevEl"><i class="iconfont">&#xe892;</i></vpt-button>
      <vpt-button size="large" :white="blackBg" ref="nextEl"><i class="iconfont">&#xe890;</i></vpt-button>
    </vpt-h-group>
    <div class="swiper-container pw-24" ref="swiperContainer" :style="{
      height: height,  
    }">
      <div class="swiper-wrapper">
        <slot></slot>
      </div>
    </div>
</vpt-v-group>
</template>

<script setup>

import Swiper from 'swiper'

import { ref, onMounted } from 'vue'

import VptButton from './VptButton.vue';
import VptHGroup from './VptHGroup.vue';
import VptText from './VptText.vue';
import VptVGroup from './VptVGroup.vue';

const props = defineProps({
  title: String,
  height: String,
  blackBg: Boolean,
})

const prevEl = ref(null)
const nextEl = ref(null)

const swiperContainer = ref(null)

onMounted(() => {
  new Swiper(swiperContainer.value, {
    loop: true,
      autoplay: {
          delay: 3000,
          disableOnInteraction: false
      },
      slidesPerView: 1,
      simulateTouch: true,
      breakpoints: {
        //当宽度小于等于768时显示
        720: {
            slidesPerView: '2',
            spaceBetween: 20
        },
      },
      navigation: {
          nextEl: nextEl.value.$el,
          prevEl: prevEl.value.$el,
      },
  })
})

</script>

<style scoped lang="scss">
</style>