<template>
  <vpt-v-group :black-bg="blackBg" >
    <div class="swiper-container pw-24" ref="swiperContainer" :style="{
      height: height,  
    }">
      <div class="swiper-wrapper">
        <slot></slot>
      </div>
    </div>
    <vpt-h-group h-align-center class="pw-24">
      <div class="my-swiper-pagination" ref="swiperPagination">
      </div>
      <vpt-button size="large" height="3.75rem" :white="blackBg" :no-border="blackBg" :secondary="blackBg" ref="nextEl"><i class="iconfont">&#xea82;</i></vpt-button>
    </vpt-h-group>
  </vpt-v-group>
</template>

<script setup>

import Swiper from 'swiper'

import { ref, onMounted } from 'vue'

import VptButton from './VptButton.vue';
import VptHGroup from './VptHGroup.vue';
import VptVGroup from './VptVGroup.vue';

const props = defineProps({
  title: String,
  height: String,
  blackBg: Boolean,
})

const swiperPagination = ref(null)
const nextEl = ref(null)

const swiperContainer = ref(null)

onMounted(() => {
  new Swiper(swiperContainer.value, {
    loop: true,
    autoplay: {
        delay: 3000,
        disableOnInteraction: false
    },
    slidesPerView: '1',
    spaceBetween: 20,
    simulateTouch: true,
    breakpoints: {
        //当宽度小于等于768时显示
        1024: {
            slidesPerView: '2',
            spaceBetween: 20
        },
    },
    pagination: {
        el: swiperPagination.value,
        bulletClass: 'bullet',
        bulletActiveClass: 'bullet-active',
        clickable: true,
    },

    navigation: {
        nextEl: nextEl.value.$el,
    },
  })
})

</script>

<style scoped lang="scss">

.my-swiper-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1.375rem;
  background-color: rgb(255, 255, 255, 0.1);
  height: 3.75rem;
  border-radius: 1.75rem;
  padding: 0 1.75rem;
  :deep(.bullet) {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.3);
    &.bullet-active {
      width: 2.5rem;
      height: 0.5rem;
      border-radius: 1rem;
      background: #FFFFFF;
      transition: all 0.5s;
    }
  }
}

</style>