<template>
  <vpt-v-group class="vpt-full-vp">
    <vpt-h-group sticky block class="iv-s vh-2">
      <vpt-button size="large" compact no-border :route="{name: 'Index'}">
        <vpt-image size="large" :src="require('./assets/img/header/logo.svg')" />
      </vpt-button>    
      <vpt-h-group h-align-left flex-grow="1">
        <vpt-button size="large" no-border :route="{name: 'Products'}">{{ $t('products') }}</vpt-button>
        <vpt-button size="large" no-border :route="{name: 'Support'}">{{ $t('support') }}</vpt-button>
        <vpt-button size="large" no-border :route="{name: 'News'}">{{ $t('news') }}</vpt-button>
        <vpt-button size="large" no-border :route="{name: 'Contact'}">{{ $t('contactUs') }}</vpt-button>
        <vpt-button size="large" no-border :route="{name: 'About'}">{{ $t('about') }}</vpt-button>
      </vpt-h-group>
      <vpt-h-group>
        <vpt-button size="large" no-border compact @click="langSwitchMenuRef.open(langSwitchMenuTriggerRef.$el)" ref="langSwitchMenuTriggerRef">
          {{ $lang() }}
        </vpt-button>	
        <vpt-button size="large" no-border compact primary @click="memberNavMenuRef.open(memberNavMenuTriggerRef.$el)" ref="memberNavMenuTriggerRef">
          <i class="iconfont">&#xe639;</i>
        </vpt-button>
        <!--
        <vpt-button size="large" no-border compact primary @click="">
          <i class="iconfont">&#xe88f;</i>
        </vpt-button>
        -->
      </vpt-h-group>
    </vpt-h-group>
  
    <vpt-h-group sticky block class="v-s">
      <vpt-button size="large" no-border compact  @click="mobileNavMenuRef.open(mobileNavMenuTriggerRef.$el)" ref="mobileNavMenuTriggerRef">
        <i class="iconfont">&#xe610;</i>
      </vpt-button>
      <vpt-button flex-grow="1" size="large" no-border :route="{name: 'Index'}">
        <vpt-image size="large" :src="require('./assets/img/header/logo.svg')" />
      </vpt-button>
      <vpt-button size="large" no-border compact @click="langSwitchMenuRef.open(mobileLangSwitchMenuTriggerRef.$el)" ref="mobileLangSwitchMenuTriggerRef">
        {{ $lang() }}
      </vpt-button>
      <vpt-button size="large" no-border compact @click="memberNavMenuRef.open(mobileMemberNavMenuTriggerRef.$el)" ref="mobileMemberNavMenuTriggerRef">
        <i class="iconfont">&#xe639;</i>
      </vpt-button>
    </vpt-h-group>

    <vpt-popup-menu sticky="right" ref="langSwitchMenuRef">
      <vpt-button align-right size="large" no-border @click="i18n.setLang('EN')">English - EN</vpt-button>
      <vpt-button align-right size="large" no-border @click="i18n.setLang('ZH')">中文 - ZH</vpt-button>
    </vpt-popup-menu>      

    <vpt-popup-menu sticky="right" ref="memberNavMenuRef">
      <vpt-button v-if="$store.state.isAuthenticated" align-right size="large" no-border :route="{name: 'Orders'}">{{ $t("orders") }}</vpt-button>
      <vpt-button v-if="$store.state.isAuthenticated" align-right size="large" no-border :route="{name: 'ShippingAddresses'}">{{ $t("shippingAddresses") }}</vpt-button>
      <vpt-button v-if="$store.state.isAuthenticated" align-right size="large" no-border :route="{name: 'AccountSettings'}">{{ $t("accountSettings") }}</vpt-button>
      <vpt-button v-if="!$store.state.isAuthenticated" align-right size="large" no-border :route="{name: 'Login'}">{{ $t("signIn") }}</vpt-button>
      <vpt-button v-if="!$store.state.isAuthenticated" align-right size="large" no-border :route="{name: 'EmailJoin'}">{{ $t("signUp") }}</vpt-button>
      <vpt-button v-if="$store.state.isAuthenticated" align-right size="large" no-border @click="logout">{{ $t("logout") }}</vpt-button>
    </vpt-popup-menu>

    <vpt-popup-menu sticky="left" ref="mobileNavMenuRef">
      <vpt-button align-left size="large" no-border :route="{name: 'Products'}">{{ $t('products') }}</vpt-button>
      <vpt-button align-left size="large" no-border :route="{name: 'Support'}">{{ $t('support') }}</vpt-button>
      <vpt-button align-left size="large" no-border :route="{name: 'News'}">{{ $t('news') }}</vpt-button>
      <vpt-button align-left size="large" no-border :route="{name: 'Contact'}">{{ $t('contactUs') }}</vpt-button>
      <vpt-button align-left size="large" no-border :route="{name: 'About'}">{{ $t('about') }}</vpt-button>
    </vpt-popup-menu>

    <router-view>
    </router-view>
  </vpt-v-group>
  <vpt-h-group wrap align-top columnGap="0" block white-bg top-radius compact class="wow animate__animated animate__fadeInUp">
    <vpt-v-group block white-bg class="pw-12">
        <vpt-text class="co">
          {{ $t('viewptCoFullName') }}
        </vpt-text>
        <vpt-h-group h-align-left align-baseline>
          <i class="iconfont">&#xe66f;</i><a  class="content" href="mailto:admin@viewpt.cn">admin@viewpt.cn</a>
        </vpt-h-group>
        <vpt-h-group h-align-left align-baseline>
          <i class="iconfont">&#xe606;</i>
          <vpt-text>{{ $t('viewptCoAddr') }}</vpt-text>
        </vpt-h-group>
    </vpt-v-group>
    <vpt-h-group wrap block white-bg class="pw-12">
      <vpt-button no-border compact :route="{name: 'Index'}">{{ $t("home") }}</vpt-button>
      <vpt-button no-border compact :route="{name: 'Products'}">{{ $t("products") }}</vpt-button>
      <vpt-button no-border compact :route="{name: 'News'}">{{ $t("news") }}</vpt-button>
      <vpt-button no-border compact :route="{name: 'Contact'}">{{ $t("contactUs") }}</vpt-button>
      <vpt-button no-border compact :route="{name: 'About'}">{{ $t("about") }}</vpt-button>
      <vpt-button v-if="$store.state.isAuthenticated" no-border compact :route="{name: 'Orders'}">{{ $t("orders") }}</vpt-button>
      <vpt-button v-if="$store.state.isAuthenticated" no-border compact :route="{name: 'ShippingAddresses'}">{{ $t("shippingAddresses") }}</vpt-button>
      <vpt-button v-if="!$store.state.isAuthenticated" no-border compact :route="{name: 'Login'}">{{ $t("signIn") }}</vpt-button>
      <vpt-button v-if="!$store.state.isAuthenticated" no-border compact :route="{name: 'EmailJoin'}">{{ $t("signUp") }}</vpt-button>
    </vpt-h-group>
    <vpt-v-group row-gap="0" h-align-center block black-bg top-radius class="pw-24 wow animate__animated animate__fadeInUp">
      <vpt-text white>{{ $t('viewptCopyRight') }}</vpt-text>
      <vpt-h-group>
        <vpt-button no-border compact white>{{ $t('privacyPolicy') }}</vpt-button>
        <vpt-button no-border compact white>{{ $t('serviceTerms') }}</vpt-button>
        <vpt-button no-border compact white>{{ $t('cookiesSettings') }}</vpt-button>
      </vpt-h-group>
      <vpt-text><a href="https://beian.miit.gov.cn/">粤ICP备17102858号</a></vpt-text>
    </vpt-v-group>
  </vpt-h-group>
</template>

<script setup>

import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'

import { logout as _logout } from '@/api'
import i18n from '@/plugins/i18n'

import VptButton from '@/components/VptButton.vue'
import VptHGroup from '@/components/VptHGroup.vue'
import VptImage from '@/components/VptImage.vue'
import VptPopupMenu from '@/components/VptPopupMenu.vue'
import VptVGroup from '@/components/VptVGroup.vue'
import VptText from './components/VptText.vue'

const store = useStore()
const router = useRouter()
const route = useRoute()

const langSwitchMenuTriggerRef = ref(null)
const memberNavMenuTriggerRef = ref(null)
const mobileNavMenuTriggerRef = ref(null)
const mobileLangSwitchMenuTriggerRef = ref(null)
const mobileMemberNavMenuTriggerRef = ref(null)
const langSwitchMenuRef = ref(null)
const memberNavMenuRef = ref(null)
const mobileNavMenuRef = ref(null)

function logout() {
  store.commit("setLogout")
  _logout()
  router.push('/')
}

</script>
<style scoped lang="scss">

.vpt-full-vp {
  min-height: 100vh;
}

</style>

<style lang="scss">

@font-face {
  font-family: 'iconfont';  /* Project id 4389177 */
  src: url('./assets/fonts/iconfont.woff2') format('woff2'),
        url('./assets/fonts/iconfont.woff') format('woff'),
        url('./assets/fonts/iconfont.ttf') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  //font-size: 16px;
  font-style: normal;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "MON";
  src: url("./assets/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "MONL";
  src: url("./assets/fonts/Montserrat-Light-6.otf");
}

* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    /* Firefox */
    -webkit-box-sizing: border-box;
    /* Safari */
    margin: 0px;
    padding: 0px;
    outline: none;
}

html, body {
  width: 100%;
  //overflow-x: hidden;
}

body {
  background-color: #f4f4f4;
  font-family: 'MON';
}

/* 宽度 */
::-webkit-scrollbar {
    width: 5px;
}

/* 背景色 */
::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

/* 滑块颜色 */
::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius:10px;
  -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2rem;
}

.title {
    margin:  8.125rem 0 3.5rem 0;
    font-family: 'MON';
}

.center {
    text-align: center;
}

.left {
    margin-left: 4rem;
}

@media only screen and (max-width:1680px) {
  html {
    font-size: 16px !important;
  }
}

@media only screen and (max-width:1440px) {
  html {
    font-size: 12px !important;
  }
}

@media only screen and (max-width:1280px) {
  html {
    font-size: 12px !important;
  }
}

@media only screen and (max-width:1024px) {
  html {
    font-size: 10px !important;
  }
}

@media only screen and (max-width:720px) {
  html {
    font-size: 10px !important;
  }  

  h1 {
      font-size: 2rem;
  }

  .center{
      margin-left: 1rem;
  }
}

.reverse-block {
  background-color: #f4f4f4;
  padding: 1rem 1rem;
  border-radius: 1.5rem;
}

// grid styles

.vw-1 {
  width: 1 / 24 * 100vw;
}
.vw-2 {
  width: 2 / 24 * 100vw;
}
.vw-3 {
  width: 3 / 24 * 100vw;
}
.vw-4 {
  width: 4 / 24 * 100vw;
}
.vw-5 {
  width: 5 / 24 * 100vw;
}
.vw-6 {
  width: 6 / 24 * 100vw;
}
.vw-7 {
  width: 7 / 24 * 100vw;
}
.vw-8 {
  width: 8 / 24 * 100vw;
}
.vw-9 {
  width: 9 / 24 * 100vw;
}
.vw-10 {
  width: 10 / 24 * 100vw;
}
.vw-11 {
  width: 11 / 24 * 100vw;
}
.vw-12 {
  width: 12 / 24 * 100vw;
}
.vw-13 {
  width: 13 / 24 * 100vw;
}
.vw-14 {
  width: 14 / 24 * 100vw;
}
.vw-15 {
  width: 15 / 24 * 100vw;
}
.vw-16 {
  width: 16 / 24 * 100vw;
}
.vw-17 {
  width: 17 / 24 * 100vw;
}
.vw-18 {
  width: 18 / 24 * 100vw;
}
.vw-19 {
  width: 19 / 24 * 100vw;
}
.vw-20 {
  width: 20 / 24 * 100vw;
}
.vw-21 {
  width: 21 / 24 * 100vw;
}
.vw-22 {
  width: 22 / 24 * 100vw;
}
.vw-23 {
  width: 23 / 24 * 100vw;
}
.vw-24 {
  width: 24 / 24 * 100vw;
}
.pw-1 {
  width: 1 / 24 * 100%;
}
.pw-2 {
  width: 2 / 24 * 100%;
}
.pw-3 {
  width: 3 / 24 * 100%;
}
.pw-4 {
  width: 4 / 24 * 100%;
}
.pw-5 {
  width: 5 / 24 * 100%;
}
.pw-55 {
  width: 5.5 / 24 * 100%;
}
.pw-6 {
  width: 6 / 24 * 100%;
}
.pw-7 {
  width: 7 / 24 * 100%;
}
.pw-8 {
  width: 8 / 24 * 100%;
}
.pw-9 {
  width: 9 / 24 * 100%;
}
.pw-10 {
  width: 10 / 24 * 100%;
}
.pw-11 {
  width: 11 / 24 * 100%;
}
.pw-12 {
  width: 12 / 24 * 100%;
}
.pw-13 {
  width: 13 / 24 * 100%;
}
.pw-14 {
  width: 14 / 24 * 100%;
}
.pw-15 {
  width: 15 / 24 * 100%;
}
.pw-16 {
  width: 16 / 24 * 100%;
}
.pw-17 {
  width: 17 / 24 * 100%;
}
.pw-18 {
  width: 18 / 24 * 100%;
}
.pw-19 {
  width: 19 / 24 * 100%;
}
.pw-20 {
  width: 20 / 24 * 100%;
}
.pw-21 {
  width: 21 / 24 * 100%;
}
.pw-22 {
  width: 22 / 24 * 100%;
}
.pw-23 {
  width: 23 / 24 * 100%;
}
.pw-24 {
  width: 24 / 24 * 100%;
}
.vh-1 {
  height: 1 / 24 * 100vh;
}
.vh-2 {
  height: 2 / 24 * 100vh;
}
.vh-3 {
  height: 3 / 24 * 100vh;
}
.vh-4 {
  height: 4 / 24 * 100vh;
}
.vh-5 {
  height: 5 / 24 * 100vh;
}
.vh-6 {
  height: 6 / 24 * 100vh;
}
.vh-7 {
  height: 7 / 24 * 100vh;
}
.vh-8 {
  height: 8 / 24 * 100vh;
}
.vh-9 {
  height: 9 / 24 * 100vh;
}
.vh-10 {
  height: 10 / 24 * 100vh;
}
.vh-11 {
  height: 11 / 24 * 100vh;
}
.vh-12 {
  height: 12 / 24 * 100vh;
}
.vh-13 {
  height: 13 / 24 * 100vh;
}
.vh-14 {
  height: 14 / 24 * 100vh;
}
.vh-15 {
  height: 15 / 24 * 100vh;
}
.vh-16 {
  height: 16 / 24 * 100vh;
}
.vh-17 {
  height: 17 / 24 * 100vh;
}
.vh-18 {
  height: 18 / 24 * 100vh;
}
.vh-19 {
  height: 19 / 24 * 100vh;
}
.vh-20 {
  height: 20 / 24 * 100vh;
}
.vh-21 {
  height: 21 / 24 * 100vh;
}
.vh-22 {
  height: 22 / 24 * 100vh;
}
.vh-23 {
  height: 23 / 24 * 100vh;
}
.vh-24 {
  height: 24 / 24 * 100vh;
}
.vh-auto {
  height: auto;
}

// xl
@media only screen and (max-width:1680px) {
  .vw-xl-1 {
    width: 1 / 24 * 100vw !important;
  }
  .vw-xl-2 {
    width: 2 / 24 * 100vw !important;
  }
  .vw-xl-3 {
    width: 3 / 24 * 100vw !important;
  }
  .vw-xl-4 {
    width: 4 / 24 * 100vw !important;
  }
  .vw-xl-5 {
    width: 5 / 24 * 100vw !important;
  }
  .vw-xl-6 {
    width: 6 / 24 * 100vw !important;
  }
  .vw-xl-7 {
    width: 7 / 24 * 100vw !important;
  }
  .vw-xl-8 {
    width: 8 / 24 * 100vw !important;
  }
  .vw-xl-9 {
    width: 9 / 24 * 100vw !important;
  }
  .vw-xl-10 {
    width: 10 / 24 * 100vw !important;
  }
  .vw-xl-11 {
    width: 11 / 24 * 100vw !important;
  }
  .vw-xl-12 {
    width: 12 / 24 * 100vw !important;
  }
  .vw-xl-13 {
    width: 13 / 24 * 100vw !important;
  }
  .vw-xl-14 {
    width: 14 / 24 * 100vw !important;
  }
  .vw-xl-15 {
    width: 15 / 24 * 100vw !important;
  }
  .vw-xl-16 {
    width: 16 / 24 * 100vw !important;
  }
  .vw-xl-17 {
    width: 17 / 24 * 100vw !important;
  }
  .vw-xl-18 {
    width: 18 / 24 * 100vw !important;
  }
  .vw-xl-19 {
    width: 19 / 24 * 100vw !important;
  }
  .vw-xl-20 {
    width: 20 / 24 * 100vw !important;
  }
  .vw-xl-21 {
    width: 21 / 24 * 100vw !important;
  }
  .vw-xl-22 {
    width: 22 / 24 * 100vw !important;
  }
  .vw-xl-23 {
    width: 23 / 24 * 100vw !important;
  }
  .vw-xl-24 {
    width: 24 / 24 * 100vw !important;
  }
  .pw-xl-1 {
    width: 1 / 24 * 100% !important;
  }
  .pw-xl-2 {
    width: 2 / 24 * 100% !important;
  }
  .pw-xl-3 {
    width: 3 / 24 * 100% !important;
  }
  .pw-xl-4 {
    width: 4 / 24 * 100% !important;
  }
  .pw-xl-5 {
    width: 5 / 24 * 100% !important;
  }
  .pw-xl-6 {
    width: 6 / 24 * 100% !important;
  }
  .pw-xl-7 {
    width: 7 / 24 * 100% !important;
  }
  .pw-xl-8 {
    width: 8 / 24 * 100% !important;
  }
  .pw-xl-9 {
    width: 9 / 24 * 100% !important;
  }
  .pw-xl-10 {
    width: 10 / 24 * 100% !important;
  }
  .pw-xl-11 {
    width: 11 / 24 * 100% !important;
  }
  .pw-xl-12 {
    width: 12 / 24 * 100% !important;
  }  
  .pw-xl-13 {
    width: 13 / 24 * 100% !important;
  }  
  .pw-xl-14 {
    width: 14 / 24 * 100% !important;
  }  
  .pw-xl-15 {
    width: 15 / 24 * 100% !important;
  }  
  .pw-xl-16 {
    width: 16 / 24 * 100% !important;
  }  
  .pw-xl-17 {
    width: 17 / 24 * 100% !important;
  }  
  .pw-xl-18 {
    width: 18 / 24 * 100% !important;
  }  
  .pw-xl-19 {
    width: 19 / 24 * 100% !important;
  }  
  .pw-xl-20 {
    width: 20 / 24 * 100% !important;
  }  
  .pw-xl-21 {
    width: 21 / 24 * 100% !important;
  }  
  .pw-xl-22 {
    width: 22 / 24 * 100% !important;
  }  
  .pw-xl-23 {
    width: 23 / 24 * 100% !important;
  }  
  .pw-xl-24 {
    width: 24 / 24 * 100% !important;
  }  
  .vh-xl-1 {
    height: 1 / 24 * 100vh !important;
  }
  .vh-xl-2 {
    height: 2 / 24 * 100vh !important;
  }
  .vh-xl-3 {
    height: 3 / 24 * 100vh !important;
  }
  .vh-xl-4 {
    height: 4 / 24 * 100vh !important;
  }
  .vh-xl-5 {
    height: 5 / 24 * 100vh !important;
  }
  .vh-xl-6 {
    height: 6 / 24 * 100vh !important;
  }
  .vh-xl-7 {
    height: 7 / 24 * 100vh !important;
  }
  .vh-xl-8 {
    height: 8 / 24 * 100vh !important;
  }
  .vh-xl-9 {
    height: 9 / 24 * 100vh !important;
  }
  .vh-xl-10 {
    height: 10 / 24 * 100vh !important;
  }
  .vh-xl-11 {
    height: 11 / 24 * 100vh !important;
  }
  .vh-xl-12 {
    height: 12 / 24 * 100vh !important;
  }
  .vh-xl-13 {
    height: 13 / 24 * 100vh !important;
  }
  .vh-xl-14 {
    height: 14 / 24 * 100vh !important;
  }
  .vh-xl-15 {
    height: 15 / 24 * 100vh !important;
  }
  .vh-xl-16 {
    height: 16 / 24 * 100vh !important;
  }
  .vh-xl-17 {
    height: 17 / 24 * 100vh !important;
  }
  .vh-xl-18 {
    height: 18 / 24 * 100vh !important;
  }
  .vh-xl-19 {
    height: 19 / 24 * 100vh !important;
  }
  .vh-xl-20 {
    height: 20 / 24 * 100vh !important;
  }
  .vh-xl-21 {
    height: 21 / 24 * 100vh !important;
  }
  .vh-xl-22 {
    height: 22 / 24 * 100vh !important;
  }
  .vh-xl-23 {
    height: 23 / 24 * 100vh !important;
  }
  .vh-xl-24 {
    height: 24 / 24 * 100vh !important;
  }
  .vh-xl-auto {
    height: auto !important;
  }
}

// large
@media only screen and (max-width:1280px) {
  .vw-l-1 {
    width: 1 / 24 * 100vw !important;
  }
  .vw-l-2 {
    width: 2 / 24 * 100vw !important;
  }
  .vw-l-3 {
    width: 3 / 24 * 100vw !important;
  }
  .vw-l-4 {
    width: 4 / 24 * 100vw !important;
  }
  .vw-l-5 {
    width: 5 / 24 * 100vw !important;
  }
  .vw-l-6 {
    width: 6 / 24 * 100vw !important;
  }
  .vw-l-7 {
    width: 7 / 24 * 100vw !important;
  }
  .vw-l-8 {
    width: 8 / 24 * 100vw !important;
  }
  .vw-l-9 {
    width: 9 / 24 * 100vw !important;
  }
  .vw-l-10 {
    width: 10 / 24 * 100vw !important;
  }
  .vw-l-11 {
    width: 11 / 24 * 100vw !important;
  }
  .vw-l-12 {
    width: 12 / 24 * 100vw !important;
  }
  .vw-l-13 {
    width: 13 / 24 * 100vw !important;
  }
  .vw-l-14 {
    width: 14 / 24 * 100vw !important;
  }
  .vw-l-15 {
    width: 15 / 24 * 100vw !important;
  }
  .vw-l-16 {
    width: 16 / 24 * 100vw !important;
  }
  .vw-l-17 {
    width: 17 / 24 * 100vw !important;
  }
  .vw-l-18 {
    width: 18 / 24 * 100vw !important;
  }
  .vw-l-19 {
    width: 19 / 24 * 100vw !important;
  }
  .vw-l-20 {
    width: 20 / 24 * 100vw !important;
  }
  .vw-l-21 {
    width: 21 / 24 * 100vw !important;
  }
  .vw-l-22 {
    width: 22 / 24 * 100vw !important;
  }
  .vw-l-23 {
    width: 23 / 24 * 100vw !important;
  }
  .vw-l-24 {
    width: 24 / 24 * 100vw !important;
  }
  .pw-l-1 {
    width: 1 / 24 * 100% !important;
  }
  .pw-l-2 {
    width: 2 / 24 * 100% !important;
  }
  .pw-l-3 {
    width: 3 / 24 * 100% !important;
  }
  .pw-l-4 {
    width: 4 / 24 * 100% !important;
  }
  .pw-l-5 {
    width: 5 / 24 * 100% !important;
  }
  .pw-l-6 {
    width: 6 / 24 * 100% !important;
  }
  .pw-l-7 {
    width: 7 / 24 * 100% !important;
  }
  .pw-l-8 {
    width: 8 / 24 * 100% !important;
  }
  .pw-l-9 {
    width: 9 / 24 * 100% !important;
  }
  .pw-l-10 {
    width: 10 / 24 * 100% !important;
  }
  .pw-l-11 {
    width: 11 / 24 * 100% !important;
  }
  .pw-l-12 {
    width: 12 / 24 * 100% !important;
  }  
  .pw-l-13 {
    width: 13 / 24 * 100% !important;
  }  
  .pw-l-14 {
    width: 14 / 24 * 100% !important;
  }  
  .pw-l-15 {
    width: 15 / 24 * 100% !important;
  }  
  .pw-l-16 {
    width: 16 / 24 * 100% !important;
  }  
  .pw-l-17 {
    width: 17 / 24 * 100% !important;
  }  
  .pw-l-18 {
    width: 18 / 24 * 100% !important;
  }  
  .pw-l-19 {
    width: 19 / 24 * 100% !important;
  }  
  .pw-l-20 {
    width: 20 / 24 * 100% !important;
  }  
  .pw-l-21 {
    width: 21 / 24 * 100% !important;
  }  
  .pw-l-22 {
    width: 22 / 24 * 100% !important;
  }  
  .pw-l-23 {
    width: 23 / 24 * 100% !important;
  }  
  .pw-l-24 {
    width: 24 / 24 * 100% !important;
  }  
  .vh-l-1 {
    height: 1 / 24 * 100vh !important;
  }
  .vh-l-2 {
    height: 2 / 24 * 100vh !important;
  }
  .vh-l-3 {
    height: 3 / 24 * 100vh !important;
  }
  .vh-l-4 {
    height: 4 / 24 * 100vh !important;
  }
  .vh-l-5 {
    height: 5 / 24 * 100vh !important;
  }
  .vh-l-6 {
    height: 6 / 24 * 100vh !important;
  }
  .vh-l-7 {
    height: 7 / 24 * 100vh !important;
  }
  .vh-l-8 {
    height: 8 / 24 * 100vh !important;
  }
  .vh-l-9 {
    height: 9 / 24 * 100vh !important;
  }
  .vh-l-10 {
    height: 10 / 24 * 100vh !important;
  }
  .vh-l-11 {
    height: 11 / 24 * 100vh !important;
  }
  .vh-l-12 {
    height: 12 / 24 * 100vh !important;
  }
  .vh-l-13 {
    height: 13 / 24 * 100vh !important;
  }
  .vh-l-14 {
    height: 14 / 24 * 100vh !important;
  }
  .vh-l-15 {
    height: 15 / 24 * 100vh !important;
  }
  .vh-l-16 {
    height: 16 / 24 * 100vh !important;
  }
  .vh-l-17 {
    height: 17 / 24 * 100vh !important;
  }
  .vh-l-18 {
    height: 18 / 24 * 100vh !important;
  }
  .vh-l-19 {
    height: 19 / 24 * 100vh !important;
  }
  .vh-l-20 {
    height: 20 / 24 * 100vh !important;
  }
  .vh-l-21 {
    height: 21 / 24 * 100vh !important;
  }
  .vh-l-22 {
    height: 22 / 24 * 100vh !important;
  }
  .vh-l-23 {
    height: 23 / 24 * 100vh !important;
  }
  .vh-l-24 {
    height: 24 / 24 * 100vh !important;
  }
  .vh-l-auto {
    height: auto !important;
  }
}

// medium
@media only screen and (max-width:1024px) {
  .vw-m-1 {
    width: 1 / 24 * 100vw !important;
  }
  .vw-m-2 {
    width: 2 / 24 * 100vw !important;
  }
  .vw-m-3 {
    width: 3 / 24 * 100vw !important;
  }
  .vw-m-4 {
    width: 4 / 24 * 100vw !important;
  }
  .vw-m-5 {
    width: 5 / 24 * 100vw !important;
  }
  .vw-m-6 {
    width: 6 / 24 * 100vw !important;
  }
  .vw-m-7 {
    width: 7 / 24 * 100vw !important;
  }
  .vw-m-8 {
    width: 8 / 24 * 100vw !important;
  }
  .vw-m-9 {
    width: 9 / 24 * 100vw !important;
  }
  .vw-m-10 {
    width: 10 / 24 * 100vw !important;
  }
  .vw-m-11 {
    width: 11 / 24 * 100vw !important;
  }
  .vw-m-12 {
    width: 12 / 24 * 100vw !important;
  }
  .vw-m-13 {
    width: 13 / 24 * 100vw !important;
  }
  .vw-m-14 {
    width: 14 / 24 * 100vw !important;
  }
  .vw-m-15 {
    width: 15 / 24 * 100vw !important;
  }
  .vw-m-16 {
    width: 16 / 24 * 100vw !important;
  }
  .vw-m-17 {
    width: 17 / 24 * 100vw !important;
  }
  .vw-m-18 {
    width: 18 / 24 * 100vw !important;
  }
  .vw-m-19 {
    width: 19 / 24 * 100vw !important;
  }
  .vw-m-20 {
    width: 20 / 24 * 100vw !important;
  }
  .vw-m-21 {
    width: 21 / 24 * 100vw !important;
  }
  .vw-m-22 {
    width: 22 / 24 * 100vw !important;
  }
  .vw-m-23 {
    width: 23 / 24 * 100vw !important;
  }
  .vw-m-24 {
    width: 24 / 24 * 100vw !important;
  }
  .pw-m-1 {
    width: 1 / 24 * 100% !important;
  }
  .pw-m-2 {
    width: 2 / 24 * 100% !important;
  }
  .pw-m-3 {
    width: 3 / 24 * 100% !important;
  }
  .pw-m-4 {
    width: 4 / 24 * 100% !important;
  }
  .pw-m-5 {
    width: 5 / 24 * 100% !important;
  }
  .pw-m-6 {
    width: 6 / 24 * 100% !important;
  }
  .pw-m-7 {
    width: 7 / 24 * 100% !important;
  }
  .pw-m-8 {
    width: 8 / 24 * 100% !important;
  }
  .pw-m-9 {
    width: 9 / 24 * 100% !important;
  }
  .pw-m-10 {
    width: 10 / 24 * 100% !important;
  }
  .pw-m-11 {
    width: 11 / 24 * 100% !important;
  }
  .pw-m-12 {
    width: 12 / 24 * 100% !important;
  }  
  .pw-m-13 {
    width: 13 / 24 * 100% !important;
  }  
  .pw-m-14 {
    width: 14 / 24 * 100% !important;
  }  
  .pw-m-15 {
    width: 15 / 24 * 100% !important;
  }  
  .pw-m-16 {
    width: 16 / 24 * 100% !important;
  }  
  .pw-m-17 {
    width: 17 / 24 * 100% !important;
  }  
  .pw-m-18 {
    width: 18 / 24 * 100% !important;
  }  
  .pw-m-19 {
    width: 19 / 24 * 100% !important;
  }  
  .pw-m-20 {
    width: 20 / 24 * 100% !important;
  }  
  .pw-m-21 {
    width: 21 / 24 * 100% !important;
  }  
  .pw-m-22 {
    width: 22 / 24 * 100% !important;
  }  
  .pw-m-23 {
    width: 23 / 24 * 100% !important;
  }  
  .pw-m-24 {
    width: 24 / 24 * 100% !important;
  }  
  .vh-m-1 {
    height: 1 / 24 * 100vh !important;
  }
  .vh-m-2 {
    height: 2 / 24 * 100vh !important;
  }
  .vh-m-3 {
    height: 3 / 24 * 100vh !important;
  }
  .vh-m-4 {
    height: 4 / 24 * 100vh !important;
  }
  .vh-m-5 {
    height: 5 / 24 * 100vh !important;
  }
  .vh-m-6 {
    height: 6 / 24 * 100vh !important;
  }
  .vh-m-7 {
    height: 7 / 24 * 100vh !important;
  }
  .vh-m-8 {
    height: 8 / 24 * 100vh !important;
  }
  .vh-m-9 {
    height: 9 / 24 * 100vh !important;
  }
  .vh-m-10 {
    height: 10 / 24 * 100vh !important;
  }
  .vh-m-11 {
    height: 11 / 24 * 100vh !important;
  }
  .vh-m-12 {
    height: 12 / 24 * 100vh !important;
  }
  .vh-m-13 {
    height: 13 / 24 * 100vh !important;
  }
  .vh-m-14 {
    height: 14 / 24 * 100vh !important;
  }
  .vh-m-15 {
    height: 15 / 24 * 100vh !important;
  }
  .vh-m-16 {
    height: 16 / 24 * 100vh !important;
  }
  .vh-m-17 {
    height: 17 / 24 * 100vh !important;
  }
  .vh-m-18 {
    height: 18 / 24 * 100vh !important;
  }
  .vh-m-19 {
    height: 19 / 24 * 100vh !important;
  }
  .vh-m-20 {
    height: 20 / 24 * 100vh !important;
  }
  .vh-m-21 {
    height: 21 / 24 * 100vh !important;
  }
  .vh-m-22 {
    height: 22 / 24 * 100vh !important;
  }
  .vh-m-23 {
    height: 23 / 24 * 100vh !important;
  }
  .vh-m-24 {
    height: 24 / 24 * 100vh !important;
  }
  .vh-m-auto {
    height: auto !important;
  }
}

// small
@media only screen and (max-width:720px) {
  .vw-s-1 {
    width: 1 / 24 * 100vw !important;
  }
  .vw-s-2 {
    width: 2 / 24 * 100vw !important;
  }
  .vw-s-3 {
    width: 3 / 24 * 100vw !important;
  }
  .vw-s-4 {
    width: 4 / 24 * 100vw !important;
  }
  .vw-s-5 {
    width: 5 / 24 * 100vw !important;
  }
  .vw-s-6 {
    width: 6 / 24 * 100vw !important;
  }
  .vw-s-7 {
    width: 7 / 24 * 100vw !important;
  }
  .vw-s-8 {
    width: 8 / 24 * 100vw !important;
  }
  .vw-s-9 {
    width: 9 / 24 * 100vw !important;
  }
  .vw-s-10 {
    width: 10 / 24 * 100vw !important;
  }
  .vw-s-11 {
    width: 11 / 24 * 100vw !important;
  }
  .vw-s-12 {
    width: 12 / 24 * 100vw !important;
  }
  .vw-s-13 {
    width: 13 / 24 * 100vw !important;
  }
  .vw-s-14 {
    width: 14 / 24 * 100vw !important;
  }
  .vw-s-15 {
    width: 15 / 24 * 100vw !important;
  }
  .vw-s-16 {
    width: 16 / 24 * 100vw !important;
  }
  .vw-s-17 {
    width: 17 / 24 * 100vw !important;
  }
  .vw-s-18 {
    width: 18 / 24 * 100vw !important;
  }
  .vw-s-19 {
    width: 19 / 24 * 100vw !important;
  }
  .vw-s-20 {
    width: 20 / 24 * 100vw !important;
  }
  .vw-s-21 {
    width: 21 / 24 * 100vw !important;
  }
  .vw-s-22 {
    width: 22 / 24 * 100vw !important;
  }
  .vw-s-23 {
    width: 23 / 24 * 100vw !important;
  }
  .vw-s-24 {
    width: 24 / 24 * 100vw !important;
  }
  .pw-s-1 {
    width: 1 / 24 * 100% !important;
  }
  .pw-s-2 {
    width: 2 / 24 * 100% !important;
  }
  .pw-s-3 {
    width: 3 / 24 * 100% !important;
  }
  .pw-s-4 {
    width: 4 / 24 * 100% !important;
  }
  .pw-s-5 {
    width: 5 / 24 * 100% !important;
  }
  .pw-s-6 {
    width: 6 / 24 * 100% !important;
  }
  .pw-s-7 {
    width: 7 / 24 * 100% !important;
  }
  .pw-s-8 {
    width: 8 / 24 * 100% !important;
  }
  .pw-s-9 {
    width: 9 / 24 * 100% !important;
  }
  .pw-s-10 {
    width: 10 / 24 * 100% !important;
  }
  .pw-s-11 {
    width: 11 / 24 * 100% !important;
  }
  .pw-s-12 {
    width: 12 / 24 * 100% !important;
  }  
  .pw-s-13 {
    width: 13 / 24 * 100% !important;
  }  
  .pw-s-14 {
    width: 14 / 24 * 100% !important;
  }  
  .pw-s-15 {
    width: 15 / 24 * 100% !important;
  }  
  .pw-s-16 {
    width: 16 / 24 * 100% !important;
  }  
  .pw-s-17 {
    width: 17 / 24 * 100% !important;
  }  
  .pw-s-18 {
    width: 18 / 24 * 100% !important;
  }  
  .pw-s-19 {
    width: 19 / 24 * 100% !important;
  }  
  .pw-s-20 {
    width: 20 / 24 * 100% !important;
  }  
  .pw-s-21 {
    width: 21 / 24 * 100% !important;
  }  
  .pw-s-22 {
    width: 22 / 24 * 100% !important;
  }  
  .pw-s-23 {
    width: 23 / 24 * 100% !important;
  }  
  .pw-s-24 {
    width: 24 / 24 * 100% !important;
  }  
  .vh-s-1 {
    height: 1 / 24 * 100vh !important;
  }
  .vh-s-2 {
    height: 2 / 24 * 100vh !important;
  }
  .vh-s-3 {
    height: 3 / 24 * 100vh !important;
  }
  .vh-s-4 {
    height: 4 / 24 * 100vh !important;
  }
  .vh-s-5 {
    height: 5 / 24 * 100vh !important;
  }
  .vh-s-6 {
    height: 6 / 24 * 100vh !important;
  }
  .vh-s-7 {
    height: 7 / 24 * 100vh !important;
  }
  .vh-s-8 {
    height: 8 / 24 * 100vh !important;
  }
  .vh-s-9 {
    height: 9 / 24 * 100vh !important;
  }
  .vh-s-10 {
    height: 10 / 24 * 100vh !important;
  }
  .vh-s-11 {
    height: 11 / 24 * 100vh !important;
  }
  .vh-s-12 {
    height: 12 / 24 * 100vh !important;
  }
  .vh-s-13 {
    height: 13 / 24 * 100vh !important;
  }
  .vh-s-14 {
    height: 14 / 24 * 100vh !important;
  }
  .vh-s-15 {
    height: 15 / 24 * 100vh !important;
  }
  .vh-s-16 {
    height: 16 / 24 * 100vh !important;
  }
  .vh-s-17 {
    height: 17 / 24 * 100vh !important;
  }
  .vh-s-18 {
    height: 18 / 24 * 100vh !important;
  }
  .vh-s-19 {
    height: 19 / 24 * 100vh !important;
  }
  .vh-s-20 {
    height: 20 / 24 * 100vh !important;
  }
  .vh-s-21 {
    height: 21 / 24 * 100vh !important;
  }
  .vh-s-22 {
    height: 22 / 24 * 100vh !important;
  }
  .vh-s-23 {
    height: 23 / 24 * 100vh !important;
  }
  .vh-s-24 {
    height: 24 / 24 * 100vh !important;
  }
  .vh-s-auto {
    height: auto !important;
  }
}

@media only screen and (min-width:1681px) {
  .iv-xxxl {
    display: none;
  }
}

@media only screen and (min-width:1441px) and (max-width:1680px) {
  .iv-xxl {
    display: none;
  }
}

@media only screen and (min-width:1281px) and (max-width:1440px) {
  .iv-xl {
    display: none;
  }
}

@media only screen and (min-width:1025px) and (max-width:1280px) {
  .iv-l {
    display: none;
  }
}

@media only screen and (min-width:721px) and (max-width:1024px) {
  .iv-m {
    display: none;
  }
}

@media only screen and (max-width:720px) {
  .iv-s {
    display: none;
  }
}

@media only screen and (min-width:1681px) {
  .v-xxxl {
    display: flex;
  }
}

@media only screen and (min-width:1441px) and (max-width:1680px) {
  .v-xxl {
    display: flex;
  }
}

@media only screen and (min-width:1281px) and (max-width:1440px) {
  .v-xl {
    display: flex;
  }
}

@media only screen and (min-width:1025px) and (max-width:1280px) {
  .v-l {
    display: flex;
  }
}

@media only screen and (min-width:721px) and (max-width:1024px) {
  .v-m {
    display: flex;
  }
}

@media only screen and (max-width:720px) {
  .v-s {
    display: flex;
  }
}

</style>