import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'Index', 
    meta: {
      requiresAuth: false
    },
    component: () => import(/* webpackChunkName: "products" */ '@/views/index.vue')
  },
  {
    path: '/products',
    name: 'Products', 
    meta: {
      requiresAuth: false
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "products" */ '@/views/products.vue')
 
  },
  {
    path: '/support',
    name: 'Support',
    meta: {
      requiresAuth: false
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "support" */ '@/views/support.vue')
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      requiresAuth: false
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/about.vue')
  },
  {
    path: '/news',
    name: 'News',
    meta: {
      requiresAuth: false
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "news" */ '@/views/news.vue')
  },
  {
    path: '/newsDetail',
    name: 'NewsDetail',
    meta: {
      requiresAuth: false
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "newsDetail" */ '@/views/newsDetail.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: {
      requiresAuth: false
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "contact" */ '@/views/contact.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      requiresAuth: false
    },
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '@/views/login.vue')
  },
  {
    path: '/phoneJoin',
    name: 'PhoneJoin',
    meta: {
      requiresAuth: false
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "phoneJoin" */ '@/views/phoneJoin.vue')
  },
  {
    path: '/emailJoin',
    name: 'EmailJoin',
    meta: {
      requiresAuth: false
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "emailJoin" */ '@/views/emailJoin.vue')
  },
  {
    path: '/retrievePassword',
    name: 'RetrievePassword',
    meta: {
      requiresAuth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "retrievePassword" */ '@/views/retrievePassword.vue')
  },
  {
    path: '/accountSettings',
    name: 'AccountSettings',
    meta: {
      requiresAuth: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "retrievePassword" */ '@/views/accountSettings.vue')
  },
  {
    path: '/placeOrder',
    name: 'PlaceOrder',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "retrievePassword" */ '@/views/placeOrder.vue')
  },
  {
    path: '/payOrder',
    name: 'PayOrder',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "retrievePassword" */ '@/views/payOrder.vue')
  },
  {
    path: '/orders',
    name: 'Orders',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "retrievePassword" */ '@/views/orders.vue')
  },
  {
    path: '/shippingAddresses',
    name: 'ShippingAddresses',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "retrievePassword" */ '@/views/shippingAddresses.vue')
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from) => {
  if (to.name === 'PhoneJoin') {
    if (store.state.isAuthenticated) return false
  } else if (to.name === 'EmailJoin') {
    if (store.state.isAuthenticated) return false
  } else if (to.name === 'Login') {
    if (store.state.isAuthenticated) {
      if (to.query?.redirect) {
        return {
          path: to.query.redirect,
          replace: true,
        };
      }
    }
  } else {
    if (!store.state.isAuthenticated && to.meta.requiresAuth) {
        return {
          name: 'Login',
          query: { redirect: to.fullPath }
        };
    }
  }
});

router.afterEach((to,from,next) => {
    window.scrollTo(0,0);
})

export default router

