import Products from "../../views/products.vue";

export default {
  about: '关于',
  all: '所有',
  accountSettings: '账号设置',
  cookiesSettings: 'Cookies设置',
  contactUs: '联系我们',
  home: '首页',
  joinWithEmail: '邮箱注册',
  joinWithPhone: '手机号注册',
  logout: '退出',
  news: '新闻',
  orders: '订单',
  password: '密码',
  pendingDelivery: '待收货',
  pendingPayment: '待支付',
  pendingShipping: '待发货',
  phoneOrEmail: '手机号或邮箱',
  privacyPolicy: '隐私保护',
  product: '产品',
  products: '产品',
  serviceTerms: '服务条款',
  shippingAddresses: '收货地址',
  signIn: '登录',
  signUp: '注册',
  support: '技术支持',
  viewptCoAddr: '广东省深圳市龙岗区吉华路446号乐荟中心13栋A区4楼A1室',
  viewptCoFullName: '深圳视点创新技术有限公司',  
  viewptCopyRight: '视点创新 版权所有',
  pay: '支付',
  cancel: '取消',
  usd: '美元',
  cny: '人民币',
  unitPrice: '单价',
  productAmount: '商品金额',
  freight: '运费',
  coupon: '优惠',
  total: '总计',
  contactPerson: '联系人',
  phoneNumber: '电话号码',
  addrDetails: '详细地址',
  modifyShippingAddress: '修改收货地址',
  submit: '提交',
  save: '保存',
  modify: '修改',
  remove: '删除',
  add: '新增',
  shipingAddresses: '收货地址',
  addShippingAddress: '新增收货地址',
  selectShippingAddress: '选择收货地址',
  realiaSlogan: '人人都可以一键完成 3DVR&3D 电影（视频）的直播&录播',
  buy: '购买',
  productParams: '产品参数',
  loginAlipayToPay: '登录支付宝账户付款',
  loginAlipayToPayPromptTitle: '请登录支付宝网站完成支付',
  orderId: '订单编号',
  paymentAmount: '付款金额',
  shippingMethod: '配送方式',
  trackNumber: '物流单号',
  contact: '联系方式',
  shippingAddress: '收货地址',
  placeOrderTime: '下单时间',
  shippingTime: '发货时间',
  deliveryTime: '收货时间',
  payTime: '付款时间',
  payMethod: '支付方式',
  faq: '常见问题',
  viewptSupport: '视点支持',
  download: '下载',
  firmware: '固件',
  userManual: '用户手册',
  ourTeam: '我们的团队',
  email: '邮箱',
  join: '注册',
  verificationCode: '验证码',
  unitSecond: '秒',
  toSend: '发送',
  toResend: '重发送',
  sending: '发送中',
  inputCaptcha: '请输入验证码',
  orderState1: '待支付',
  orderState15: '支付确认中',
  orderState2: '待发货',
  orderState3: '待收货',
  orderState4: '已收货',
  orderState10: '已完成',
  orderState11: '已取消',
  confirmReceipt: '确认收货',
  whyChooseViewpt: '为什么选择视点创新',
  viewpt: '视点创新',
  modifyEmail: '修改邮箱',
  newEmail: '新的邮箱',
  modifyPassword: '修改密码',
  oldPassword: '旧密码',
  newPassword: '新密码',
  modifyPhoneNumber: '修改电话号码',
  newPhoneNumber: '新的电话号码',
  inputValidPhoneNumberOrEmail: '请输入有效的手机号或邮箱',
  inputPasswd: '请输入密码',
  inputValidEmail: '请输入有效的邮箱',

}
